<template>
    <main>
  
      <div class="container">
        <b-jumbotron id="jumbotron" border-variant="dark" header-level="5">
          <template #header>{{$t('message.policy_privacy')}}</template>
          <template #lead>
              {{$t('message.policy_privacythx')}}
          </template>
        </b-jumbotron>
      </div>
  
      <!-- Page Content -->
      <div class="container" id="termsContainer">
          <h4 class="headerunderline">{{$t('message.privacy_scope')}}</h4>
          <p class="leads">
          {{$t('message.policy_scopetxt')}}
          </p>
  
          <h4 class="headerunderline">{{$t('message.privacy_aim')}}</h4>
          <p class="leads">
          {{$t('message.policy_secconfidentiality')}} <u>{{$t('message.controller')}}</u> {{$t('message.controllernext')}}
          </p>
  
          <p class="leads">
              {{$t('message.privacy_prpr')}}
          </p>
  
          <h4 class="headerunderline">{{$t('message.privacypr')}}</h4>
          <p class="leads">
              {{$t('message.grspoprt')}}
          </p>
  
          <h4 class="headerunderline">{{$t('message.privacyprconsent')}}</h4>
  
          <p class="leads">
              {{$t('message.privacywithknog')}} <u>{{$t('message.privacywithknogu')}}</u> {{$t('message.privacywithknogunxt')}}
          </p>
  
          <h4 class="headerunderline">{{$t('message.privacyprdatatitle')}}</h4>
          <p class="leads">
              {{$t('message.privacywecolllect')}}
          </p>
  
          <p class="leads">
              {{$t('message.privacywecolllectddata')}}
          </p>
  
          <ul class="leads">
              <li>{{$t('message.privacywecolllectddatalia')}}</li>
              <li>{{$t('message.privacywecolllectddatalib')}}</li>
              <li>{{$t('message.privacywecolllectddatalic')}}</li>
              <li>{{$t('message.privacywecolllectddatalid')}}</li>
              <li>{{$t('message.privacywecolllectddatalie')}}</li>
          </ul>
  
          <p class="leads">
              {{$t('message.privacyweconncert')}}
          </p>
  
          <p class="leads">
              {{$t('message.privacywecdvde')}}
          </p>
  
  
          <h4 class="headernounderline">{{$t('message.privacywecdvdecontatti')}}</h4>
          <p class="leads">
              {{$t('message.privacywecdvdecontatticont')}}
          </p>
  
          <h4 class="headernounderline">{{$t('message.privacywecpaymtnecontatti')}}</h4>
          <p class="leads">
              {{$t('message.privacywecpaymtnecontattiontc')}}
          </p>
  
          <h4 class="headernounderline">{{$t('message.privacydevandloctitle')}}</h4>
  
          <p class="leads">
              {{$t('message.privacydevandloctxt')}}
          </p>
  
          <h4 class="headernounderline">{{$t('message.privacyinfousintitle')}}</h4>
  
          <p class="leads">
              {{$t('message.privacyinfousintcg')}}
          </p>
  
          <h4 class="headernounderline">{{$t('message.privacyprefftitle')}}</h4>
          <p class="leads">
              {{$t('message.privacyprefftct')}}
          </p>
  
          <h4 class="headernounderline">{{$t('message.privacycommtitle')}}</h4>
          <p class="leads">
              {{$t('message.privacycommtxt')}}
          </p>
  
          <h4 class="headernounderline">{{$t('message.privacycretedtitle')}}</h4>
          <p class="leads">
              {{$t('message.privacycretedtxt')}}
          </p>
  
          <h4 class="headernounderline">{{$t('message.privacysmdtitle')}}</h4>
          <p class="leads">
              {{$t('message.privacysmdtxt')}}
          </p>
  
          <h4 class="headerunderline">
              <u>{{$t('message.privacypdcollltitle')}}</u>
          </h4>
  
  
          <p class="leads">
              {{$t('message.privacypdcollltxt')}}
          </p>
  
          <ul class="leads">
              <li>{{$t('message.privacypdcollltxtlia')}}</li>
              <li>{{$t('message.privacypdcollltxtlib')}}</li>
              <li>{{$t('message.privacypdcollltxtlic')}}</li>
          </ul>
  
          <h4 class="headerunderline">
              {{$t('message.strgtitle')}}
          </h4>
  
          <p class="leads">
              {{$t('message.strgtxtf')}}
          </p>
          <p class="leads">
              {{$t('message.strgtxtc')}}
          </p>
          <p class="leads">
              {{$t('message.strgtxtt')}}
          </p>
          <ul class="leads">
              <li>{{$t('message.strgtxttlia')}}</li>
              <li>{{$t('message.strgtxttlib')}}</li>
              <li>{{$t('message.strgtxttlic')}}</li>
          </ul>
  
          <p class="leads">
              {{$t('message.strgtxttafterli')}}
          </p>
  
          <p class="leads">
              {{$t('message.strgtxttafterliwithu')}} <u>{{$t('message.strgtxttafterliwithuu')}}</u> {{$t('message.strgtxttafterliwithuulast')}}
          </p>
  
          <h4 class="headerunderline">{{$t('message.privacypdtattt')}}</h4>
  
          <p class="leads">{{$t('message.privacypdtatttxt')}}</p>
  
          <ul class="leads">
              <li>{{$t('message.privacypdtattlia')}}</li>
              <li>{{$t('message.privacypdtattlib')}}</li>
              <li>{{$t('message.privacypdtattlic')}}</li>
              <li>{{$t('message.privacypdtattlid')}}</li>
              <li>{{$t('message.privacypdtattlie')}}</li>
              <li>{{$t('message.privacypdtattlif')}}</li>
              <li>{{$t('message.privacypdtattlig')}}.</li>
          </ul>
  
          <p class="leads">{{$t('message.privacycoconct')}}</p>
  
          <ul class="leads">
              <li>{{$t('message.privacycoconctlia')}}</li>
              <li>{{$t('message.privacycoconctlib')}}</li>
          </ul>
  
          <h4 class="headerunderline">
              {{$t('message.privacycoconcttitle')}}
          </h4>
  
          <p class="leads">{{$t('message.privacycoconcttixt')}}</p>
  
          <p class="leads">{{$t('message.privacyslcted')}} <u>{{$t('message.privacyslctedu')}}</u> {{$t('message.privacyslctedunct')}}</p>
  
          <p class="leads">{{$t('message.privacyslctedlocdd')}}</p>
  
          <p class="leads">{{$t('message.privacyslctedlocddtxt')}}</p>
  
          <ul class="leads">
              <li>{{$t('message.privacyslctedloclia')}}</li>
              <li>{{$t('message.privacyslctedloclib')}}</li>
              <li>{{$t('message.privacyslctedloclic')}}</li>
              <li>{{$t('message.privacyslctedloclid')}}</li>
              <li>{{$t('message.privacyslctedloclif')}}</li>
              <li>{{$t('message.privacyslctedloclig')}}</li>
          </ul>
  
          <p class="leads">
              {{$t('message.privacythirdpppart')}}
          </p>
  
          <h4 class="headerunderline">{{$t('message.privacythirdpppartitle')}}</h4>
  
          <p class="leads">{{$t('message.privacythirdpppartxt')}}</p>
  
          <h4 class="headerunderline">{{$t('message.privacythirdddpppartitle')}}</h4>
          <p class="leads">{{$t('message.privacythirdddpppartxt')}}</p>
  
          <p class="leads">{{$t('message.privacythirdddpppartxtsc')}}</p>
  
          <p class="leads">{{$t('message.privacythirdddpppartxtsth')}}</p>
  
          <h4 class="headerunderline">{{$t('message.privacyssstoretlt')}}</h4>
          <p class="leads">{{$t('message.privacyssstoretltxt')}}</p>
  
          <h4 class="headerunderline">{{$t('message.privacyssstoretltallow')}}</h4>
          <p class="leads">{{$t('message.privacyssstoretltallowtxt')}}</p>
  
          <h4 class="headerunderline">{{$t('message.privactoptionsti')}}</h4>
          <p class="leads">{{$t('message.privactoptionstitxt')}}</p>
          <!--  -->
          <template>
              <p class="leads" v-html="$t('message.privactoyoucccan', 
                  { 
                      cs_link: `<a style='text-decoration: underline;' href='mailto:customer-support@safetravel.today'>${$t(' customer-support@safetravel.today')}</a>` 
                  })">
              </p>
          </template>
          <!--  -->
  
          <h4 class="headerunderline">{{$t('message.privactoyoguarantees')}}</h4>
          <ol>
              <li>{{$t('message.privactoyoguaranteeslia')}}</li>
              <li>{{$t('message.privactoyoguaranteeslib')}}</li>
              <li>{{$t('message.privactoyoguaranteeslic')}}</li>
              <li>{{$t('message.privactoyoguaranteeslid')}}</li>
              <li>{{$t('message.privactoyoguaranteeslie')}}</li>
              <li>{{$t('message.privactoyoguaranteeslif')}}</li>
          </ol>
  
          <p class="leads">{{$t('message.privacyttechnical')}}</p>
  
          <h4 class="headerunderline">{{$t('message.privacyttechnicaltxt')}}</h4>
  
          <ul class="leads">
              <li>{{$t('message.privacyttechnicallia')}}</li>
              <li>{{$t('message.privacyttechnicallib')}}</li>
              <li>{{$t('message.privacyttechnicallic')}}</li>
              <li>{{$t('message.privacyttechnicallid')}}</li>
              <li>{{$t('message.privacyttechnicallie')}}</li>
              <li>{{$t('message.privacyttechnicallif')}}</li>
              <li>{{$t('message.privacyttechnicallig')}}</li>
              <li>{{$t('message.privacyttechnicallih')}}</li>
          </ul>
  
          <p class="leads">
              {{$t('message.privacytrestricted')}}
          </p>
  
          <template>
              <p class="leads" v-html="$t('message.privacytrestrictedtwo', 
                  { 
                      compliance_link: `<a style='text-decoration: underline;' href='mailto: compliance@safetravel.today'>${$t('compliance@safetravel.today')}</a>` 
                  })">
              </p>
          </template>
  
          <h4 class="headerunderline"><u>{{$t('message.privacytrestrictcooli')}}</u></h4>
          <p class="leads">{{$t('message.privacytrestrictcoolitxt')}}</p>
  
          <h4 class="headerunderline">{{$t('message.privacywatcooki')}}</h4>
  
          <p class="leads">
              {{$t('message.privacywatcookitxt')}} <u>{{$t('message.privacywatcookitxtto')}}</u>
          </p> 
          <p class="leads">{{$t('message.privacywatcookitxtthr')}}</p>
  
      
          <h4 class="headerunderline">{{$t('message.privacywatcookitxttfour')}}</h4>
  
          <p class="leads">
              {{$t('message.privacywatcookitxttfive')}}
          </p>
  
          <h4 class="headerunderline">{{$t('message.privacytrccode')}}</h4>
  
          <p class="leads">
              {{$t('message.privacytrccodetxt')}}
          </p>
  
          <h4 class="headerunderline">{{$t('message.privacytrccodetxttoo')}}</h4>
  
          <template>
              <p class="leads" v-html="$t('message.privacytrccodetxtnha', 
                  { 
                      customer_link: `<a style='text-decoration: underline;' href='mailto:customer-support@safetravel.today?subject=Personal data complaint'>${$t('here')}</a>` 
                  })">
              </p>
          </template>
  
          <template>
              <p class="leads" v-html="$t('message.privacytrccodetxtnhabc', 
                  { 
                      email_link: `<a style='text-decoration: underline;' href='mailto:customer-support@safetravel.today'>${$t('customer-support@safetravel.today')}</a>` 
                  })">
              </p>
          </template>
  
          <p class="leads">
              {{$t('message.privacymodificationa')}} <u>{{$t('message.privacymodificationb')}}</u> {{$t('message.privacymodificationc')}}
          </p>
      </div>
    </main>
  </template>
  
  <script>
  import {
    BRow, BCol,BJumbotron 
  } from 'bootstrap-vue'
  export default {
    components: {
      BRow,
      BCol,
      BJumbotron 
    },
    data() {
      return {
      }
    },
  }
  </script>